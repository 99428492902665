@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


.invoice-patient-card {
    background-color: white;
    border-radius: 10px;
    margin-right: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
}

.invoice-patient-title {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    color: #564c60;
    font-weight: 600;
    margin-bottom: 4%;
}

.invoice-buttons-card {
    background-color: white;
    border-radius: 10px;
    margin-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.invoice-buttons-title {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    color: #564c60;
    font-weight: 600;
    margin-top: 5%;
    margin-left: 5%;
}

.invoice-buttons-total {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 24px !important;
    color: #564c60;
    font-weight: 600;
    margin-left: 5%;
    margin-bottom: 7%;
}

.invoice-machine-title {
    color: #564c60;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 22px;
    text-align: center;
}

.invoice-patient-info {
    color: #857c8e;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
}

.invoice-patient-card-date {
    color: gray;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 15px;
}

.invoice-buttons-separator {
    margin-left: 5%;
    margin-right: 5%;
}

.invoice-patient-modal-title {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 24px;
}

.invoice-button-container {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.invoice-generate-button {
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: #38baff;
    text-transform: uppercase;
    border-radius: 10px;
    border: 1px solid #38baff;
    padding: 4% 22% 4% 22%;
    width: 90%;
    text-decoration: none;
}

.invoice-generate-button-disabled {
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: gray;
    text-transform: uppercase;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 4% 22% 4% 22%;
    width: 90%;
    text-decoration: none;
}

.invoice-generate-button:hover {
    transition: 0.3s;
    color: #38baff;
    background-color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #38baff;
}

.invoice-patient-select {
    width: 40vw;
}


.invoice-patient-card-info {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    margin-bottom: 10px;
}

.add-product-button {
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: #38baff;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #38baff;
    padding: 4% 10% 4% 10%;
    height: 100%;
    width: 100%;
    text-decoration: none;
}

.add-product-button-image {
    width: 36px;
    height: 30px;
}

.invoice-diagnosis-accordion-master {
    background-color: white !important;
    border-radius: 10px !important;
    margin-bottom: 5px !important;
    width: 100%;
}

.invoice-patient-accordion-master {
    background-color: white !important;
    border-radius: 10px !important;
    margin-bottom: 5px !important;
    width: 98%;
}

.invoice-patient-accordion-summary {
    background-color: #F4F5FA;
    border-radius: 30px;
    margin-bottom: 5px !important;
}

.MuiAccordion-root:before {
    opacity: 0 !important;
}

.MuiAccordionDetails-root {
    display: block !important;
    justify-content: space-between !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.centered {
    position: fixed;
    /* or absolute */
    top: 50%;
    left: 50%;
}

.invoice-generator-frame-big {
    background-color: white;
    border-radius: 6px;
    margin-right: 5px;
    margin-top: 19px;
}

.invoice-generator-frame-small {
    background-color: white;
    border-radius: 6px;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 19px;
}

.invoice-generator-frame-title {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 22px;
}

.invoice-generator-frame-title-container {
    padding-top: 10px;
}



.product-details-price {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.invoice-add-product-button {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 16px;
    color: #38baff;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
}

.delete-icon {
    height: 30px;
}


.product-autocomplete .MuiAutocomplete-clearIndicator {
    display: none !important;
}

.summary-table-products {
    width: 40%;
}

.summary-table-price {
    width: 20%;
}

.summary-table-qty {
    width: 20%;
}

.summary-table-subtotal {
    width: 20%;
}

.invoices-list-invoiced-badge {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #47bfb2;
    border-radius: 4px;
    background-color: #c2f2ec;
}

.invoices-list-warning-badge {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #ffa30f;
    border-radius: 4px;
    background-color: #ffeac7;
}

.invoices-list-cancel-badge {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgb(250, 250, 250);
    border-radius: 4px;
    background-color: #a9a4afc7
}

.invoices-list-unpaid-badge {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #ef6945;
    border-radius: 4px;
    background-color: #ffc8ba;
}

.invoices-list-partialpaid-badge {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #38baff;
    border-radius: 4px;
    background-color: #bae7ff;
}

.invoice-menu-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #564c60;
}

.invoice-menu-item {
    height: 30px;
    margin-left: -10%;
    text-align: right;
    padding-left: 0px;
}

.beta-sign {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #47bfb2;
    border-radius: 4px;
    background-color: #c2f2ec;
}

.table-header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.table-content-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.restore-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: var(--primary-color);
}

table {
    table-layout: fixed;
    width: 90%;
}

th,
td {
/*     width: fit-content;
 */    height: 20px;
    overflow: hidden;
}

.invoices-frame {
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 10px;
}

.rectangle-invoices {
    border: solid 2px #e1dfe5;
    border-radius: 6px;
    padding: 26px 32px 17px 51px;
}

.rectangle-invoices p {
    color: #e1dfe5;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

#current-invoices-table0 {
    display: none;
}

#current-invoices-table7 {
    display: none;
}

#current-invoices-table8 {
    display: none;
}

#current-patient-table0 {
    display: none;
}

#current-patient-table4 {
    display: none;
}

#current-patient-table5 {
    display: none;
}

#patient-table0 {
    display: none;
}

#patient-table7 {
    display: none;
}

#patient-table8 {
    display: none;
}

.inputs-alingment input {
    text-align: end !important;
}

.patientInvoices-download-icon {
    color: var(--primary-color);
}