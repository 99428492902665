 
 .bg_primary-color {
    background-color: #FFFFFF;
  }
  .primary-color {
    background-color: #FFFFFF;
  }
  .bg_secondary-color {
    background-color: #293241;
  }
  .secondary-color {
    color: #293241;
  }
  .custom-button.btn {
    background-color: #293241;
    color: #FFF;
    border: none;
  }
  .custom-button.btn:hover {
    color: #FFF !important;
    border: none;
  }
  .hero {
    width: 100vw;
    height: 50vh;
  }
  .hero img {
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
  }
  .overlay {
    height: 100%;
    width: 100%;
    opacity: 0.4;
  }

  body {
    background-color: #F4F5FA;
  }
  
  /* Style page content */
  .main {
    margin-left: 80px; /* Same as the width of the sidebar */
    padding: 0px 0px;
    background-color: #FFFFFF;   
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  
  /* Style the navigation bar */
  .navbar {
    width: 100%;
    background-color: #F4F5FA;
    align-items: center;
    display: flex;
    padding-top: 32px;
    /*border: 2px solid red;*/
    border-top-left-radius: 30px 30px;
    }
  
  /* For bell and avatar icon*/
  .second-nav{
      width: 100%;
      justify-content: flex-end;
      display: flex;
  }    
  /* Navbar links */
  .navbar a {
    float: left;
    text-align: center;
    padding: 12px;
    color: #1e2125;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Navbar links on mouse-over */
  .navbar a:hover {
    background-color: #F4F5FA;
  }

  .doctor-content {
    padding-left: 100px;
    background-color: #F4F5FA;
  }

  
  div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
  }  

  .card {
    width: 299px;
  }
  
  
  /* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
  @media screen and (max-width: 500px) {
    .navbar a {
      float: none;
      display: block;
    }
  }



  /* Dashboard  */
 


  /* Cards */
  .tcm-card-now{
    width: 300px!important;
    height: 106px!important;
    margin: 19px 13px 33px 1px!important;
    padding: 8px 1px 11.9px 23px!important;
    background-color: var(--primary-color)!important;
    border-radius: 10px!important;
    padding-left: 0px!important;
  }



  .inside-card-title-now{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px!important;
    font-weight: bold;
    color:white!important;
  }
  .inside-card-text-now{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px!important;
    font-weight: 300;
    color:white!important;
  }
  .tcm-card-after{
    width: 300px!important;
    height: 106px!important;
    margin: 19px 13px 33px 1px!important;
    padding: 8px 1px 11.9px 23px!important;
    background-color: #FFFFFF!important;
    border-radius: 10px!important;
    padding-left: 0px!important;
  }

  .inside-card-title-after{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px!important;
    font-weight: bold;
    color:#564c60!important;
  }
  .inside-card-title-after-done {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px!important;
    font-weight: bold;
    color:#564c60!important;
    opacity: 0.3;
  }

  .inside-card-text-after{
    font-family: 'Open Sans', sans-serif!important;
    font-size: 16px!important;
    font-weight: 100!important;
    color:#564c60!important;
  }
  .inside-card-text-after-done {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 16px!important;
    font-weight: 100!important;
    color:#564c60!important;
    opacity: 0.3;
  }

  .all-reminders-box-text{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px!important;
  }
  /* Reminders box */
  .tcm-card-reminders{
    width: 649px;
    height: 444px;
    margin: 10px 16px 0 0;
    padding: 25px 21.5px 102.2px 18px;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: auto;
  }
  /* All Reminders Box */
  .tcm-all-reminders-box{
    border-radius: 10px;
  }
  /* Today reminders box */
  .tcm-today-reminders-box{
    border-radius: 10px;
  }
  .tcm-today-reminders-box-done{
    border-radius: 10px;
  }
  /* Files box */
  .tcm-card-files{
    width: 416px;
    height: 444px;
    margin: 10px 0 0 16px;
    padding: 30px 26px 47px 25px;
    background-color: #ffffff;
  }

  /* Reminders grid*/

  .anchor-reminders{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #38baff!important;
    text-decoration: none;
  }
  .anchor-reminders:hover{
    color: #38baff!important;
    background-color: #FFFFFF;
  }
  .reminder-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color:#564c60;
  }
  .reminder-text{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color:#564c60;
  }
  
  /* Reminders collapse*/
  .accordion-button{
    background-color: #F4F5FA!important;
  }
  
  .accordion-item:last-of-type .accordion-button.collapsed{
    font-family: 'Open Sans', sans-serif!important;
    font-size: 16px;
    color:#564c60!important;
    font-weight: bold!important;
  }
  
  .accordion-button:not(.collapsed){
    font-family: 'Open Sans', sans-serif!important;
    font-size: 16px;
    color:#564c60!important;
    font-weight: bold!important;
  }
  .accordion-body{
    background-color: #F4F5FA!important;
  }
  /* calendar */

  
/* sidebar movil */

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  transition: margin-right .5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}

.button {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 20px;
  padding: 8px 17px;
  border: none;
  background-color: #38baff;
}

/* user menu hover */

.on-hov:hover > .list-1 {
  display: block;
}

.list-1 {
  list-style-type: none;
  display: none;
  position: absolute;
  padding: 10px;
  width: 150px;
  height: 100px;
  top: 63px;
  right: 20px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  background:white;
  color: #212529;
  border: none;
  z-index: 9999;
}

/* Loading image */

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
  padding-top: 20%;
}

/* login */

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

/*FOR SUBS*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* CITATION */
.title-citation-box{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color:#564C60;
    font-weight: bold;
    
}
.subtitle-citation-box{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color:#857C8E;
}
.subtitle-citation-box-info{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color:#857C8E;
}
.inside-title-citation-box{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color:#564C60;
    font-weight: bold;
}

/* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
    .form-control-width-citation{
      width: 100px;
    } 
}
  
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
  .form-control-width-citation{
    width: 200px;
  } 
}
  
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
  .form-control-width-citation{
    width: 400px;
  } 
}

.button-citation-box-2{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    border: 2px solid #7BD1FF;
    border-radius: 5px;
    color: white;
    background-color: #7BD1FF;
    padding: 10px 15px 10px 15px;
}
.button-citation-box-2-save{
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  border: 2px solid #7BD1FF;
  border-radius: 5px;
  color: white;
  background-color: #7BD1FF;
  padding: 10px 55px 10px 55px;
}
.button-citation-box-2-cancel{
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  border: 2px solid #7BD1FF;
  border-radius: 5px;
  color: #7BD1FF;
  background-color: white;
  padding: 10px 55px 10px 55px;
}
.button-citation-box-2-complete{
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  border: 2px solid #7BD1FF;
  border-radius: 5px;
  color: #7BD1FF;
  background-color: white;
  padding: 10px 55px 10px 55px;
}
.button-citation-box-2-save:hover{
  border: 2px solid #7BD1FF;
  color: #7BD1FF;
  background-color: white;
  transition: 0.5s;
}
.button-citation-box-2-cancel:hover{
  border: 2px solid #7BD1FF;
  color: white;
  background-color: #7BD1FF;
  transition: 0.5s;
}
.button-citation-box-2-complete:hover{
  border: 2px solid #7BD1FF;
  color: white;
  background-color: #7BD1FF;
  transition: 0.5s;
}
.button-citation-box-2:hover{
  border: 2px solid #7BD1FF;
  color: #7BD1FF;
  background-color: white;
  transition: 0.5s;
}
.appointment-status{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color:#38BAFF;
    background-color: #255670;
}
.appointment-status-upcoming{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color:#47bfb2;
  background-color: #84e5da;
}

.appointment-status-saved{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color:#47bfb2;
  border-radius: 4px;
  background-color: #c2f2ec;
}

.appointment-status-unsaved{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #f3a63e;
  border-radius: 4px;
  background-color: #fcebcb;
}

.appointment-status-archived{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(250, 250, 250);
  border-radius: 4px;
  background-color: #a9a4afc7;
}

.appointment-status-saving {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #1aaaf8;
  border-radius: 4px;
  background-color: #cae6fa;
}

.citation-box {   
    max-width: 735px;   
    height: 680px;
    margin-bottom: 2%;   
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;   
    background-color: #ffffff;   
    display: grid;   
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}

.citation-box-warning {   
  height: 744px;
}

.citation-box-patient {   
  width: 680px;   
  height: 640px;
  margin-bottom: 2%;   
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;   
  background-color: #ffffff;   
  display: grid;   
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}
.row-0{
    display: grid; 
    grid-row: 1;
    padding-top: 5%;
}
.row-1{
    display: grid; 
    grid-row: 2;
    padding: 10px 30px 20px 0px;   
    grid-template-columns: 15% 20% 20% 20%; 
}
.row-2{
    display: grid; 
    grid-row: 3;
}
.row-3{
    
    grid-row: 4;
}
.row-4{
    grid-row: 5;
    padding-top: 2%;
    padding-bottom: 2%;
}

.alert {
  margin-bottom: 0px!important;
}

.box-refresh {
  color: #ef9208 !important;
}

.MuiInputBase-input {
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 400!important;
  color:#857C8E!important;
}

.citation-box-patient-mobile{
  display: none;
}

@media screen and (max-width: 600px) {
  .citation-box-patient{
    display: none;
  }
  .citation-box-patient-mobile{
    display: block;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 35px;
    padding-left: 40px;
    border-radius: 10px;
    height: auto;
    margin-bottom: 30px;

  }  
  
}  