.main-zunge {
    background-image: url("../../assets/icons/Zunge.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 290px;
    height: 403px;
}
.ni-zunge {
    position: relative;
    top: 30px;
    left: 33px;
    background-image: url("../../assets/icons/zunge-components/ni.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.ma-zunge {
    position: relative;
    top: -10px;
    left: 89px;
    background-image: url("../../assets/icons/zunge-components/ma.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 116px;
    height: 259px;
    z-index: 8888;
    cursor: pointer;
}
.le-zunge-left {
    position: relative;
    top: -270px;
    left: 32px;
    background-image: url("../../assets/icons/zunge-components/le-left.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 88px;
    height: 272px;
    cursor: pointer;
}
.le-zunge-right {
    position: relative;
    top: -545px;
    left: 172px;
    background-image: url("../../assets/icons/zunge-components/le-right.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 88px;
    height: 272px;
    cursor: pointer;
}
.lu-zunge {
    position: relative;
    top: -590px;
    left: 72px;
    background-image: url("../../assets/icons/zunge-components/lu.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 151px;
    height: 69px;
    z-index: 9988;
    cursor: pointer;
}
         
.he-zunge {
    position: relative;
    top: -610px;
    left: 90px;
    background-image: url("../../assets/icons/zunge-components/he.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 112px;
    height: 44px;
    z-index: 9999;
    cursor: pointer;
}
.unsaved-div:hover{
    cursor: pointer;
    transition: .3s;
    box-shadow: 0 0 2px #f3a63e;
    transform: scale(.9);
}
