@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.title-modal{
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20pt;
    color: #564c60;
}

.subtitle-modal{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 13pt;
    color: #564c60;
    line-height: 1.6;
}

.save-citation-button {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}

.add-patient-button {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    background: #FFFFFF;
    color: #1CABF7;
    border-radius: 5px;
    border-style: solid;
    border-color: #1CABF7;
    width: 60px;
    height: 30px;
    text-transform: uppercase;
}

.cancel-citation-button {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    background: #FFFFFF;
    color: #1CABF7;
    border-radius: 5px;
    border-color: #1CABF7;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}

.schedule-title {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    color: #564c60;
    line-height: 1.6;
}

.reference-container {
    padding: 5px 5px;
    border-radius: 2px;
    background: #ffeac7!important;
}

.reference-text {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    color: #ffa30f!important;
}

.box-available {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    color: #47bfb2!important;
    background: #c2f2ec!important;
}

.box-unavailable {
    font-family: 'Open Sans', 'sans-serif';
    font-weight:  bold;
    font-size: 12px;
    color: #ffa30f!important;
    background: #ffeac7!important;
}