.floating-div {
    position: fixed;
    border-radius: 25px;
    border-style: solid;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    right: 12px;
    bottom: 22px;
    z-index: 1;
    cursor: pointer;
}

.reminder-button {
    background-color: var(--secondary-color);
    border-radius: 25px;
    margin-bottom: 5px;
    cursor: pointer;
}

.support-button {
    width: 42px;
    height: 42px;
    border-radius: 25px !important;
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
    font-size: 28px !important;
    padding: 0px 9px !important;
}

.help-button {
    width: 42px;
    height: 42px;
    border-radius: 25px !important;
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
    font-size: 28px !important;
    padding: 0px 9px !important;
    border: none !important;
    margin-bottom: 5px !important;
}

.button-group {
    position: fixed;
    z-index: 99;
    border-radius: 25px !important;
    border-style: solid;
    border-color: #ffffff;
    background-color: #ffffff;
    right: 12px;
    bottom: 22px;
}