body {   
    background-color: #F4F5FA!important;
    overflow-y: scroll;
    height: 100vh;
    -webkit-overflow-scrolling:touch; 
}

.cuadro{
    margin-top: 3%!important;
    padding-left: 100px!important;
    padding-right: 100px!important;
}

.MuiPickersToolbar-toolbar{
    background-color: var(--primary-color) !important;
}
.MuiPickersDay-daySelected{
    background-color: var(--primary-color) !important;

}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.result-box {   
    /* width: 800px;    */
    height: 115px;   
    padding: 10px 30px 20px 0px;   
    border-radius: 10px;   
    background-color: #ffffff;   
    display: grid;   
    grid-template-columns: 20% 30% 25% 25%; 
}

.inputTitle{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 13pt;
    color: #564C60;
}

.btnGo{
    font-family: 'Open Sans', sans-serif;
    font-weight:  bold;
    font-size: 14px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}

/* TITLES AND SUBTITLES */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
/* PARAGRAPHS */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

/* CITATIONS */

.new-appointment-back-link {
    margin-top: 14px;
    margin-bottom: 14px;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 16px;
    color: #38baff;
}

.result-box-summary-data {
    font-family: 'Open Sans', sans-serif!important;
    font-weight: 600;
    font-size: 25px;
    color: #564c60;
}

.title-citation {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #564C60;
}

.subtitle-citation{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #564c60;
}
.doctor-content-2{
    background-color: #F4F5FA;
}

/* New citations */
.result-box-mobile{
    display: none;
}

@media screen and (max-width: 600px) {
    .result-box-mobile{
        display: block;
    }
    .nopadding{
        padding-left: 0px!important;
    }
    .invert-column{
        flex-direction: column-reverse;
    }
    .cuadro {
        padding-left: 10%!important;
        padding-right: 10%!important;
    }
    .MuiTypography-h4{
        font-size: 16px !important;
    }
    .NewCitation-btnBack-6{
        width: 100% !important;
    }
    .NewCitation-btnGo-5{
        width: 100% !important;
        margin-bottom: 10px;
    }
    .MuiInput-underline:before{
        width: 85% !important;
    }
    .result-box{
    display: none;
    }
    .result-box .text-center.align-self-center{
        display: none;
    }
    .makeStyles-nav-9 {
    padding-left: 0px!important;
    padding-right: 0px!important;   
    }
    .result-box-mobile{
        display: block;
        padding-left: 40px;
        border-radius: 10px;
        background: white;
        width: 100% !important;
        height: auto !important;
    }
    .result-box-mobile h3{
        margin-top: 20px;
    }
    .result-box-mobile h5{
        margin-top: 10px;
        padding-bottom: 20px;
    }    
   
}

/* Complete Citation */
.clinical-findings-box{
  padding: 33.3px 23.4px 21.6px 33.8px;
  border-radius: 7px;
  border: solid 2px #f4f5fa;
  background-color: #FFFFFF;
  width: 96%;
}

@media screen and (min-width: 992px){
    .no-padding-desktop{
        padding-left: 0px!important;
    }
}
