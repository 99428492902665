.schedule-modal {
    border-radius: 10px;
}

.new-lock-btn{
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: #38baff;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #38baff;
    padding: 10px 30px 10px 30px;
    margin-right: 15px;
    text-decoration: none;
}

.new-lock-btn-disabled{
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: gray;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 10px 30px 10px 30px;
    margin-right: 15px;
    text-decoration: none;
}