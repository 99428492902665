.border-rounded {
    border-radius: 10px;
}

.list-item {
    border: none;
}

.list-item-gray {
    border: none;
    background-color: #e8e9ed;
}