@import url('https://fonts.googleapis.com/css2?family=Rubik');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');


.patient-name-span {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    font-size: 25px;
    color: #564c60;
}

.patient-date-span {
    font-family: 'Rubik', sans-serif;
    font-weight: 100;
    font-size: 25px;
    color: #564c60;
}

.patient-back-span {
    font-family: 'Rubik', sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: #564c60;
}

.patient-edit-history {
    font-family: 'Rubik', sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: #564c60;
}

.patient-info-box {
    padding: 16px 0px 0px 28px;
    margin-top: 27px;
    background-color: white;
    border-radius: 10px;
}

.patient-info-box-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color:#564C60;
    font-weight: bold;
}

.patient-info-box-name {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color:#564C60;
    font-weight: bold;
}

.patient-warning-box {
    margin-top: 27px;
    background-color: white;
    border-radius: 10px;
}

.patient-upload-box {
    margin-top: 27px;
    background-color: white;
    border-radius: 10px;
}

.upload-box {
    height: 300px;
}

.files-box {
    height: 150px;
    overflow-y: auto;
}

.file-name {
    font-size: 14px; 
    font-weight: 400;
    color:#857c8e;
}


.info-nav-link:hover{
    background: var(--secondary-color);
    color: #564c60;
}

.info-nav-link.active{
    background-color: var(--secondary-color)!important;
    color: #564c60!important;
}

.complete-citation-info-grid{
    display: grid;
    grid-template-columns: .3fr .6fr 1fr .8fr;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    align-items: center;
}

.blue-anchors-save{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #38baff!important;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (min-width: 600px){
    .upload-files-btn{
        width: 88px;
    }
}
@media screen and (max-width: 1024px){
    .complete-citation-info-grid{
        grid-template-columns: auto;
    }

  
    
} 