@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.treatments {
    padding-right: 80px;
}

.tables {
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 10px #FFFFFF;
}

.tables th {
    border-bottom: none !important;
    border-top: none !important;
}

.tables tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.card-text-main {
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.tables tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.tables tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.tables tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.table-striped>tbody>tr:nth-child(odd)>th,
.table-striped>tbody>tr:nth-child(odd)>td {
    background-color: #F4F5FA;
}

.treatment-btn {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #FFFFFF !important;
    border-radius: 4px;
    padding: 8px 17px;
    border: none;
    background-color: #38baff;
    text-decoration: none;
}


.main-title-treatments {
    font-family: 'Rubik', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
}

.main-subtitle-treatments {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 18px;
}

.table-header-text {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    color: #857C8E;
}

.treatments-table {
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 10px #FFFFFF;
}

.table .table-more-options {
    text-align: right;
}

.product-rectangle {
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

.doctor-color-tag {
    font-size: 10px;
}

.btnGo {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}

.btnCancel {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    background: #FFFFFF;
    color: #1CABF7;
    border-radius: 5px;
    border: 1px solid #1CABF7;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}

.form-widht {
    width: 45%;
}

.search-product-input {
    height: 44px;
    width: 30%;
    padding-left: 15px;
    border-radius: 5px;
}

#arrow-treatments-table0 {
    display: none;
}

#arrow-treatments-table1 {
    display: none;
}

#arrow-treatments-table4 {
    display: none;
}

#arrow-treatments-table5 {
    display: none;
}

#arrow-treatments-table6 {
    display: none;
}

#arrow-treatments-table7 {
    display: none;
}

#arrow-products-table0 {
    display: none;
}

#arrow-products-table1 {
    display: none;
}

#arrow-products-table4 {
    display: none;
}