@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.main-title-treatments{
    font-family: 'Rubik', sans-serif!important;
    font-size: 25px;
    font-weight: bold;
}
    
.main-subtitle-treatments{
    font-family: 'Open Sans', sans-serif!important;
    font-size: 18px;
}

.title-treatments{
    font-family: 'Open Sans', sans-serif;
    font-weight:  bold;
    color :#564C60;
    font-size: 14px;
}
.MuiChip-root{
    background-color: var(--secondary-color)!important;
    
}
.MuiInputLabel-outlined{
    font-size: 0.6rem!important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink{
    display: none!important;
}
.MuiChip-label {
    color: var(--primary-color)!important;
}
.MuiChip-deleteIcon{
    color: var(--primary-color)!important;
}
.MuiOutlinedInput-adornedEnd{
    padding-top: 3px!important;
    padding-bottom: 3px!important;
}
.btnGo{
    font-family: 'Open Sans', sans-serif;
    font-weight:  bold;
    font-size: 14px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}
.btnGo-disabled {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    background-color: grey;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}
.btnCancel{
    font-family: 'Open Sans', sans-serif;
    font-weight:  bold;
    font-size: 14px;
    background: #FFFFFF;
    color: #1CABF7;
    border-radius: 5px;
    border: 1px solid #1CABF7;
    width: 220px;
    height: 40px;
    text-transform: uppercase;
}