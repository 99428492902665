@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.reminders-cards{
    border: none;
}
/*.reminders-subtitle {
    align-items: center;
    color: #564c60;
    font-family: 'Open Sans', sans-serif!important;
    font-weight: 600;
    font-size: 16px;
}*/
.reminders-subtitle {
    font-size: 16px; 
    font-weight: bold;
    color: #564c60;
}

.reminder-box {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #F4F5FA;
}

.unchecked {
    align-items: center;
    margin-left: 15px;
    padding-top: 5px;
    width: 24px;
}

.reminder-title {
    align-items: center;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 18px;
}

.reminder-body {
    align-items: center;
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px;
    margin-bottom: 1.5px;
}

.reminder-icons {
    align-items: center;
    height: 30px;
    padding-right: 3px;
}

.reminders-calendar {
    border-radius: 10px;
    height: 48vh;
    background-color: white;
    margin-bottom: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.reminders-calendar-separator {
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
}

.reminders-calendar-card {
    background-color: white;
    margin-bottom: 10px;
}

.reminders-calendar-card-square {
    margin: auto;
    text-align: center;
    width: 39px;
    height: 48px;
    border-style: solid;
    border-color: #cdcbd1;
    border-radius: 3px;
    border-width: 1px;
}

.reminders-calendar-card-title {
    font-family: 'Open Sans', sans-serif;
    color: #564c60;
    font-size: 14px;
    cursor: pointer;
}
.reminders-calendar-card-title:hover{
    color: #38baff;
    cursor: pointer;
}
.reminders-calendar-card-hour{
    font-family: 'Open Sans', sans-serif;
    color: #564c60;
    font-size: 14px;
}
.reminders-calendar-card-month {
    font-family: 'Open Sans', sans-serif;
    color: #857c8e;
    font-size: 12px;
}

.reminders-calendar-card-day {
    font-family: 'Open Sans', sans-serif;
    color: #857c8e;
    font-size: 18px;
}
.reminders-calendar-arrow-div {
    align-items: center;
    text-align: center;
    bottom: 0;
}
.reminders-calendar-arrow-icon {
    margin: auto;
    width: 14.5px;
    height: 6.9px;
}

.to-do-badge {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    color: #47bfb2;
    font-size: 12px;
    border-radius: 3px;
    background-color: #c2f2ec;
}

.important-badge {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    color:#f3a63e;
    font-size: 12px;
    border-radius: 3px;
    background-color: #ffeac7;
}

.pin-button {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    border-radius: 3px;
    border-style: solid;
    border-color: #837d8d;
    color: #564c60;
}

.reminders-toolbox-filter {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 7px;
    background-color: #ffffff;
    border-radius: 5px;
}