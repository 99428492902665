body {
    background: #F4F5FA;
}

/* .frame {
    padding-top: 30px;
    padding-left: 150px;
} 
*/
.btngo {
    font-weight: bold;
    font-size: 14px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 220px;
    height: 40px;
    margin-right: 1%;
    text-transform: uppercase;
}

.titles {
    font-size: 25pt;
    color: #564C60;
    font-weight: 900;

}

.subTitles {
    font-size: 14pt;
    color: #857C8E;
    font-weight: normal;

}

/* 25/8/2022 styles from root class component*/

.root {
    background: #F4F5FA;
}

.inputTitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 13pt;
    color: #564C60;
}

.btnGo-NewCitation {
    font-weight: bold;
    font-size: 14px;
    background: #1CABF7;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 300px;
    height: 40px;
    margin-right: 1%;
    text-transform: uppercase;
}

.formControl-NewCitation {
    min-width: 280px !important;
    border-radius: 5px !important;
    margin-bottom: 12px !important;
}

@media(max-width: 600px) {
    .btnGo-NewCitation {
        width: 100%;
    }
}

@media(min-width: 700px) and (max-width: 1024px) {
    .btnGo-NewCitation {
        width: 100%;

    }

    .formControl-NewCitation {
        margin-right: 10px !important;
    }
}

.btnBack {
    font-weight: bold;
    font-size: 14px;
    background: #837D8D;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    width: 300px;
    height: 40px;
    margin-right: 1%;
    text-transform: uppercase;
}


@media(max-width: 600px) {
    .btnBack {
        width: 100%;
    }
}

@media(min-width: 700px) and (max-width: 1024px) {
    .btnBack {
        width: 100%;
    }
}

.hoursGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}

@media(max-width: 600px) {
    .hoursGrid {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}