/*  REPLACED FOR BACKGROUND IMAGE

.left-hand {
    background-image: url("../../assets/icons/leftHand.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 118px;
    height: 228px;
    z-index: 9999;
    margin-top: 90px;
}
.right-hand {
    background-image: url("../../assets/icons/rightHand.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 118px;
    height: 228px;
    z-index: 9999;
    margin-top: 90px;
}
.rechts{
    position: relative;
    top: -60px;
    left: -50px;
    width: 227px;
    height: 97px;
}
.niere {
    position: relative;
    top: -90px;
    left: -45px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.milz{
    position: relative;
    top: -110px;
    left: -55px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.lunge{
    position: relative;
    top: -140px;
    left: -45px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.links-right-hand{
    position: relative;
    top: -60px;
    left: -65px;
    width: 227px;
    height: 97px;
}
.niere-right {
    position: relative;
    top: -90px;
    left: -65px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.leber {
    position: relative;
    top: -110px;
    left: -75px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.herz {
    position: relative;
    top: -140px;
    left: -65px;
    width: 227px;
    height: 97px;
    z-index: 9999;
    cursor: pointer;
}
.circle{
    position: relative;
    top: 0px;
    left: -25px;
    height: 430px;
    width: 430px;
    background-color: white;
    border-radius: 50%;
    z-index: 1;     
}
*/
.pulse-background{
    background-image: url("../../assets/icons/pulseCircle.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 413px;
    height: 413px;
    z-index: 9999;
    margin-right: 18px;
}
.pulse-background-translation{
    background-image: url("../../assets/icons/pulseCircleTranslation.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 413px;
    height: 413px;
    z-index: 9999;
    margin-right: 18px;
}