.container-profile-settings {
    padding-bottom: 45px;
}

.frame-profile-settings {
    padding-top: 30px;
    padding-left: 150px;
}

.container-user-profile {
    width: 75%;
    margin-left: 0px;
    padding: 20px 0px;
}

.container-user-account {
    width: 50%;
    margin-left: 0px;
    padding: 20px 35px;
}

/* mobile */
@media(max-width: 600px) {
    .container-profile-settings {
        overflow-y: scroll;
        max-height: calc(100vh - 214px);
        padding-left: 15px;
        padding-right: 15px;
    }

    .frame-profile-settings {
        padding-left: 20px;
        padding-right: 20px;
    }

    .container-user-profile {
        width: 100%;
    }

    .container-user-account {
        width: 100%;
    }

    .table-user-profile {
        display: table !important;
    }
}
