@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.frame {
    padding-left: 150px;
    padding-top: 30px;
}

.title-neue {
    font-family: 'Rubik', sans-serif !important;
    font-weight: 500;
    font-size: 21pt;
    color: #564c60;
}

.padding-p {
    padding: 0 10%;
}

.paragraph-neue {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 13pt;
    color: #857c8e;
    line-height: 1.6;

}

.check {
    font-size: 11pt;

}

.subtitle-praxis {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16pt;
    color: #857c8e;
}

.homepage-treatments-cards-text-title {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bolder;
    color: white;
    font-size: 16px;
    margin-bottom: 7px;
}

.homepage-treatments-cards-text-content {
    font-family: 'Open Sans', sans-serif !important;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
}

.homepage-treatments-cards-text-title-purple {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bolder;
    color: #564c60;
    font-size: 16px;
    margin-bottom: 7px;
}

.homepage-treatments-cards-text-content-purple {
    font-family: 'Open Sans', sans-serif !important;
    color: #857c8e;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
}

.tables {
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 10px #FFFFFF;
}

.patients-table {
    padding-top: 38px;
}

.tables th {
    border-bottom: none !important;
    border-top: none !important;
}

.tables tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.tables tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.tables tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.tables tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.table-striped>tbody>tr:nth-child(odd)>th,
.table-striped>tbody>tr:nth-child(odd)>td {
    background-color: #F4F5FA;
    color: #564c60;

}

.main-title {
    font-family: 'Rubik', sans-serif !important;
    font-size: 25px;
    font-weight: bold;
}

.main-subtitle-treatments {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 18px;
}

.table-header-text {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    color: #857C8E;
}

.treatments-table {
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 10px #FFFFFF;
}

.table>:not(:last-child)>:last-child>* {
    color: #564c60;
    font-weight: bold;
}

.table-borderless>:not(caption)>*>* {
    color: #564c60;
}

.table>tbody {
    vertical-align: initial;
}

.table .table-more-options {
    text-align: right;
}

.product-rectangle {
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

.doctor-color-tag {
    font-size: 10px;
}

.loading {
    align-content: center;
}

/* CARDS */

#card0 {
    background-color: var(--primary-color);
}

#card0 h4 {
    font-family: 'Open Sans', sans-serif !important;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
}

#card0 p {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bolder;
    color: white;
    font-size: 16px;
    margin-bottom: 7px;
}

.home-cards {
    width: 250px;
    border-radius: 10px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.today-reminder-box {
    padding-top: 22px;
    margin-left: 33px;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    color: #564C60;
}

.today-reminder-hr {
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
}

.home-reminders-big-box {
    width: 50%;
    margin-top: 2%;
    background-color: white;
    border-radius: 10px;
}

.home-reminders-box {
    height: 40vh;
    background-color: white;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.home-reminders-title {
    margin-top: 3%;
    font-size: 16px;
    font-weight: bold;
    color: #564c60;
}

.home-reminders-individual-card {
    margin-top: 5px;
}

.home-reminders-individual-info {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    color: #564C60;
    cursor: pointer;
}

.home-reminders-individual-info:hover {
    color: #38baff;
    cursor: pointer;
}

.home-reminders-individual-time {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    color: #564C60;
}

.home-titles-container {
    display: flex;
    justify-content: space-between;
    width: 37.5%;
}


@media screen and (min-width: 1500px) {
    .home-titles-container {
        width: 26.9%;
    }

}

@media screen and (max-width: 600px) {
    .frame {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 45px;
    }

    .subtitle-praxis {
        text-align: center;
    }

    .paragraph-neue {
        text-align: center;
    }

    .table {
        display: block !important;
        overflow-x: scroll !important;
        width: 100% !important;
    }
}