.frame-NeueTermin {
    padding-top: 30px;
    padding-left: 150px;
}

.frame-homePatient {
    padding-left: 150px;
    padding-top: 30px;
}

.frame-quiz {
    padding-left: 150px;
    padding-top: 30px;
}

.button-homePatient {
    cursor: pointer;
    height: 40px;
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    background-color: #38baff;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #38baff;
    text-decoration: none;
}

.button-homePatient:hover {
    transition: 0.3s;
    color: #38baff;
    background-color: #ffffff;
    text-decoration: none;
    border: 1px solid #38baff;
}

.table-homePatient {
    display: table !important;
}

.modal-Header-recommendationsPatients {
    background: #F4F5FA;
    flex-direction: column;
}

.container-iconButton-recommendationsPatients {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.iconButton-recommendationsPatients {
    padding: 6px !important;
}

.modal-Title-recommendationsPatients {
    width: 100%;
}

.modal-Body-recommendationsPatients {
    background: #F4F5FA;
}

/* mobile */
@media(max-width: 600px) {
    .frame-NeueTermin {
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .frame-homePatient {
        padding-left: 20px;
        padding-top: 0px;
    }

    .frame-quiz {
        padding-left: 20px;
        padding-right: 20px;
    }

    .container-homePatient {
        overflow-y: scroll;
        max-height: calc(100vh - 214px);
        /* margin-top: 30px; */
    }

    .container-NeueTermin {
        overflow-y: scroll;
        max-height: calc(100vh - 214px);
    }    

    .container-quiz {
        overflow-y: scroll;
        max-height: calc(100vh - 214px);
        padding-left: 15px;
        padding-right: 15px;
    }

    .padding-right-mobile {
        padding-right: 20px;
    }

    .padding-step-NeueTermin {
        padding-right: 32px;
    }

    .text-inside-container-mobile {
        white-space: normal;
    }
}