.body {
    background-color: #F4F5FA;
}

.form-padding {
    padding-left: 100px;
}

.login-background {
    background-image: url("../../assets/icons/login.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signup-background {
    background-image: url("../../assets/icons/signin.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo-padding {
    padding-left: 100px;
    height: 40px;
}

@media only screen and (max-width: 600px) {
    .form-padding {
        padding-left: 0px;
    }

    .logo-padding {
        padding-left: 0px;
    }
}

.blue-anchors {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #38baff !important;
    text-decoration: none;
}

.assist-link {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #38baff !important;
    text-decoration: none;
    cursor: pointer;
}

.change-assist-link {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #38baff !important;
    text-decoration: none;
    cursor: pointer;
}

.blue-btn {
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;
    background-color: #38baff;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #38baff;
    padding: 10px 30px 10px 30px;
    margin-right: 15px;
    text-decoration: none;
}

.blue-btn-disabled {
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: grey;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 10px 30px 10px 30px;
    margin-right: 15px;
    text-decoration: none;
}

.blue-btn:hover {
    transition: 0.3s;
    color: #38baff;
    background-color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #38baff;
}

.red-btn {
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;
    background-color: #f96d82;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #f96d82;
    padding: 10px 30px 10px 30px;
    margin-right: 15px;
    text-decoration: none;
}

.red-btn:hover {
    transition: 0.3s;
    color: #f96d82;
    background-color: #FFFFFF;
    text-decoration: none;
    border: 1px solid #f96d82;
}

.btn-cancel {
    font-weight: bold;
    font-size: 12px;
    background: #FFFFFF;
    color: #1CABF7;
    border-radius: 5px;
    border: 1px solid #1CABF7;
    padding: 10px 30px 10px 30px;
    text-transform: uppercase;
    margin-right: 10px;
    text-decoration: none;
}

.btn-cancel:hover {
    color: #FFFFFF;
    background-color: #38baff;
    border: 1px solid #38baff;
}

.btn-gray-save {
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    background-color: #a9a4af;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #a9a4af;
    padding: 10px 50px 10px 50px;
    margin-right: 15px;
    text-decoration: none;

}

.btn-gray-cancel {
    font-weight: bold;
    font-size: 12px;
    color: #a9a4af;
    text-align: center;
    background-color: #FFFFFF;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #a9a4af;
    padding: 10px 50px 10px 50px;
    margin-right: 15px;
    text-decoration: none;

}

/* Nav */

.navbar-links {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    color: #857c8e;
}

.navbar-links:hover {
    color: #564c60;
}

.MuiList-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

/* forms */
.form-group {
    margin-bottom: 1.7rem;
}

.title-inputs {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #564C60;
    font-size: 14px;
}

.subtitle-inputs {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #564C60;
    font-size: 14px;
}

.required-asterisk {
    color: red;
    margin-left: 4px;
}

.input-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media(min-width: 1200px) {
    .form-control-width {
        min-width: 320px !important;
    }
}

/* Styles for tabs*/
.nav-link {
    border: none !important;
    color: #564C60;
    transition: none !important;

}

.nav-tabs {
    z-index: 9999 !important;
}

.nav-tabs .nav-link:hover {
    margin-bottom: 0 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: none;
    isolation: unset;
    color: #564C60;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
    margin-bottom: 0 !important;
}

.nav-tabs .nav-link {
    margin-bottom: 0px !important;

}

.nav-item .nav-link:hover {
    border: 0px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #F4F5FA;
    border-color: #F4F5FA #F4F5FA #F4F5FA;
    border-color: none !important;
    border-bottom: 2px solid var(--primary-color) !important;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        .NeueTermin-root-1>.row {
            margin-bottom: 80px;
        }
    }
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: var(--primary-color) !important;
}

/* icons */
.icon {
    fill: var(--primary-color);
}