/* Patients table */
.pagination-btn {
  font-family: "Open Sans, sans-serif";
  font-weight: bold;
  font-size: 15px;
  background: none;
  color: #564c60;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  margin-right: 2px;
  margin-left: 2px;
}

.new-patient-btn {
  height: 8%;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  background-color: #38baff;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid #38baff;
  padding: 1.05% 30px 1.05% 30px;
  margin-right: 15px;
  text-decoration: none;
}

.search-field {
  width: 40%;
  height: 8%;
  border-radius: 10px;
  border: solid 2px #f4f5fa;
  margin-right: 15px;
  color: #857c8e;
  padding: 13px 17.6px 13.7px 13.1px;
  background-color: #fbfbfb;
  font-size: 16px;
  background-image: url("../../assets/icons/search.png");
  background-size: 30px;
  background-position-x: 97%;
  background-position-y: center;
  background-repeat: no-repeat;
}

.new-patient-btn:hover {
  transition: 0.3s;
  color: #38baff;
  background-color: #ffffff;
  text-decoration: none;
  border: 1px solid #38baff;
}

.list-patient {
  table-layout: auto !important;
}
.list-patient th {
  width: fit-content;
}
#recent5 {
  text-align: right;
}
/* Patients profile */

.patient-id-box {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  background-color: #ffffff;
}
.subtitles-patients {
  font-size: 16px;
  font-weight: 600;
  color: #564c60;
}
.key-text-box {
  font-size: 16px;
  font-weight: 400;
  color: #857c8e;
}

.button-column {
  padding-right: 0 !important;
}

.key-text-box-edit-button {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 7%;
  margin-right: 0;
  margin-left: 42%;
  border-radius: 10px;
}

.key-text-box-edit {
  font-size: 16px;
  font-weight: bold;
  color: #5eb9f9;
  margin-top: 6px;
  cursor: pointer;
}

.value-text-box {
  font-size: 16px;
  font-weight: 500;
  color: #564c60;
}

.edit-patient-link {
  width: 35px;
  cursor: pointer;
}

.edit-patient-div:hover {
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 0 2px #5eb9f9;
  transform: scale(0.9);
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.herbs-accordion {
  margin-bottom: 21px !important;
  background-color: white !important;
  border-radius: 10px !important;
  width: 98%;
}

.herb-accordion-body {
  background: #f4f5fa;
  padding: 27px;
  border-radius: 9px;
}

.herb-accordion-table {
  background-color: #ffffff;
  border-radius: 9px;
  border-style: solid;
  border-color: #e1dfe5;
  border-width: 1px;
  border-collapse: separate;
}

.herb-table-major-combinations {
  background-color: #e1dfe5;
  border-radius: 9px;
  border-style: solid;
  border-color: #e1dfe5;
  border-width: 1px;
  border-collapse: separate;
}

.herb-accordion-table-actions-and-indications {
  background-color: #e1dfe5;
  border-radius: 9px;
  /* border-style: solid;
  border-color: #e1dfe5;
  border-width: 1px;
  border-collapse: separate; */
}

.herb-accordion-table-actions-and-indications-thead {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-left: 32px !important;
  border-left-width: 1px;
}

.herb-accordion-table-actions-and-indications-tbody {
  padding: 13px 18px !important;
  border-left-width: 1px;
  background: #ffffff !important;
  border-color: #ffffff;
}

.herb-card-table-actions-and-indications {
  border-radius: 9px;
  box-shadow: 0px 0px 3px #564c60;
  padding: 23px 10px !important;
}

.herb-accordion-table-th {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
  padding-left: 32px !important;
  border-left-width: 1px;
}

.herb-accordion-table-major-combinations {
  /* padding-top: 21px !important; */
  /* padding-bottom: 21px !important; */
  padding-left: 32px !important;
  padding-right: 32px !important;
  /* border-left-width: 1px; */
  vertical-align: baseline;
  /* border-bottom-width: 1px; */
  background-color: #ffffff !important;
}

.herb-accordion-table > tr > th:first-child {
  border-left-width: 0px;
}

.herb-accordion-table > tr > th:last-child > * {
  border-bottom-width: 0px;
}

.herb-accordion-table-major-combinations > tr > th:last-child > * {
  border-bottom-width: 0px;
}

._NGZSv {
  background: transparent !important;
}

._i5--j {
  height: 100% !important;
}

.table > :not(:last-child) > :last-child > * {
  font-weight: normal;
}

.herb-mc-titles {
  font-weight: bold !important;
  color: #564c60;
  background-color: #e1dfe5 !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.herb-ai-titles {
  font-weight: bold !important;
  color: #564c60;
}

.bnt-primary-nih {
  color: #ffffff;
  background-color: #38baff;
  border-color: #38baff;
  padding: 12px 77px;
  text-transform: uppercase;
}

.bnt-primary-nih:hover {
  color: #38baff;
  background-color: transparent;
  border-color: #38baff;
}

.bnt-secondary-nih {
  color: #38baff;
  background-color: transparent;
  border-color: #38baff;
  margin-right: 13px;
  padding: 12px 77px;
  text-transform: uppercase;
}

.bnt-secondary-nih:hover {
  color: #ffffff;
  background-color: #38baff;
  border-color: #38baff;
}

#herbs-table0{
  display: none;
}

#herbs-table6{
  display: none;
}

#herbs-table7{
  display: none;
}

#herbs-table-td2{
  max-width: 10px;
}

#herbs-table-td4{
  max-width: 10px;
}