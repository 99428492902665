/* Patients table */
.pagination-btn {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 15px;
  background: none;
  color: #564C60;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  margin-right: 2px;
  margin-left: 2px;
}

.new-patient-btn {
  height: 8%;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  background-color: #38baff;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid #38baff;
  padding: 1.05% 30px 1.05% 30px;
  margin-right: 15px;
  text-decoration: none;
}

.search-field {
  width: 40%;
  height: 8%;
  border-radius: 10px;
  border: solid 2px #f4f5fa;
  margin-right: 15px;
  color: #857c8e;
  padding: 13px 17.6px 13.7px 13.1px;
  background-color: #FBFBFB;
  font-size: 16px;
  background-image: url("../../assets/icons/search.png");
  background-size: 30px;
  background-position-x: 97%;
  background-position-y: center;
  background-repeat: no-repeat;
}

.new-patient-btn:hover {
  transition: 0.3s;
  color: #38baff;
  background-color: #FFFFFF;
  text-decoration: none;
  border: 1px solid #38baff;
}

.list-patient {
  table-layout: auto !important;
}

.list-patient th {
  width: fit-content;
}

#recent5 {
  text-align: right;
}

.table-appointment-cell {
  overflow: auto;
}

/* Patients profile */

.patient-id-box {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.subtitles-patients {
  font-size: 16px;
  font-weight: 600;
  color: #564c60;
}

.key-text-box {
  font-size: 16px;
  font-weight: 400;
  color: #857c8e;
}

.button-column {
  padding-right: 0 !important;
}

.key-text-box-edit-button {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 7%;
  margin-right: 0;
  margin-left: 42%;
  border-radius: 10px;
}

.key-text-box-edit {
  font-size: 16px;
  font-weight: bold;
  color: #5eb9f9;
  margin-top: 6px;
  cursor: pointer;
}

.value-text-box {
  font-size: 16px;
  font-weight: 500;
  color: #564c60;
}

.edit-patient-link {
  width: 35px;
  cursor: pointer;
}

.edit-patient-div:hover {
  cursor: pointer;
  transition: .3s;
  box-shadow: 0 0 2px #5eb9f9;
  transform: scale(.9);
}

.profile-avatar {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  width: 126px !important;
  height: 126px !important;
}

/* Changed MUI Switch Color */
.MuiSwitch-colorSecondary.Mui-checked {
  color: #38baff !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #38baff !important;
}

/* Patients arrow img */

#patients-table0 {
  display: none;
}

#patients-table1 {
  display: none;
}

#patients-table7 {
  display: none;
}

/* PatientList */
.patientList-avatar {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  width: 28px !important;
  height: 28px !important;
}